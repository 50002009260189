import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { ProgressBar } from "primereact/progressbar"

import { capitalizedFirst } from "src/utils/capitalized-first-character"
import { UserModuleControllerApi } from "@soctrip/axios-business-central-service"
import { configHeader } from "src/config/interceptors"
import { SERVICE } from "src/constants/common"
import ImgCustom from "src/components/img-custom"
import ShopAdminModal from "src/components/modal/shop-admin-modal"
import EmptyPage from "../empty-page"
import { getMyShop } from "src/services/user-service"
import { handlePromiseAll } from "src/utils/common"
import { useSearchParams } from "react-router-dom"

import { ShopAdminType } from "src/components/modal/shop-admin-modal/types"
import { ShopControllerApi } from "@soctrip/axios-shop-service"
// import { useToast } from "src/hooks/use-toast"

export const MODULE_TYPE = {
  SOCIAL: "SOCIAL",
  SHOP: "SHOP",
  RESTAURANT: "RESTAURANT",
  HOTEL: "HOTEL",
  TRAVEL: "TRAVEL_TOUR",
  CAR_RENTAL: "CAR_RENTAL",
  CAR: "CAR",
  SHOP_ADMIN: "SHOP_ADMIN",
  SUPPLIER: "SUPPLIER",
}
export interface ModuleType {
  id: string
  icon: string
  label: string
  type: string
  active: boolean
}
export default function HomePage() {
  const { t } = useTranslation()
  const [data, setData] = useState<ModuleType[]>([])
  const [loading, setLoading] = useState(true)
  const [isOpenShopsAdmin, setIsOpensShopsAdmin] = useState<boolean>(false)
  const [isNotHaveData, setIsNotHaveData] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")
  const [myShopId, setMyShopId] = useState<string>("")
  const [isSupplier, setIsSupplier] = useState<boolean>(false)

  const [searchParams] = useSearchParams()

  const [shops, setShops] = useState<ShopAdminType[]>([])
  const [isSpecShop, setIsSpecShop] = useState<boolean>(false)
  const [isHaveShop, setIsHaveShop] = useState<boolean>(true)
  // const showToast = useToast()


  const getSupplierData = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return getMyShop().then((res: any) => {
      if(res?.data?.data) {
        setMyShopId(res.data.data)
      }
    })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .catch((err: any) => console.log(err))
  }

  function getModules() {
    return new UserModuleControllerApi(configHeader(SERVICE.BUSINESS_CENTRAL))
      .getMyModule()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((res: any) => {
        if (res?.data?.data?.modules?.length) {
          const result: ModuleType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.modules.forEach((i: any) => {
            const itemData: ModuleType = {
              id: i?.id || "",
              icon: i?.image?.id || "",
              label: i?.name || "",
              type: i?.type || "",
              active: i?.activated,
            }
            if(itemData?.type !== MODULE_TYPE.SHOP_ADMIN) result.push(itemData)
          })
          if (result.length) {
            setIsSupplier(result.some((item) => item.type === "SUPPLIER"))
            setData(result)
          }
        } else {          
          setIsNotHaveData(true)
          setErrorMessage(capitalizedFirst(t("global.no-data-found")))
        }
      })
      .catch(() => {        
        setIsNotHaveData(true)
        setErrorMessage(capitalizedFirst(t("global.please-refresh-page")))
      })
  }
  
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const apis = [] as any []
    apis.push(getModules())
    apis.push(getSupplierData())
    apis.push(getShops())
    handlePromiseAll(apis).then(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if(!loading && data.length && (!isSupplier || (isSupplier && shops.length > 1)) ) {
      const isShowModal = searchParams.get("isShowShopDialog")
      if(isShowModal === "true") setIsOpensShopsAdmin(true)
      
    }
  }, [loading, data, isSupplier])

  const handleClickModule = (type: string, haveData: boolean) => {
    let urlRedirect = ""
    switch (type) {
    case MODULE_TYPE.RESTAURANT:
      urlRedirect = haveData
        ? `${process.env.REACT_APP_FOOD_MANAGEMENT_DOMAIN_URL}`
        : `${process.env.REACT_APP_FOOD_DOMAIN_URL}/food/request-open-restaurant`
      break
    case MODULE_TYPE.HOTEL:
      urlRedirect = `${process.env.REACT_APP_HOTEL_BETA_DOMAIN_URL}/ota/hotel-ota`
      break
    case MODULE_TYPE.SHOP:
      if (isSupplier&&(shops.length==1)) return
      setIsOpensShopsAdmin(true)
      break
    case MODULE_TYPE.SOCIAL:
      urlRedirect = `${process.env.REACT_APP_HOME_DOMAIN_URL}`
      break
    case MODULE_TYPE.CAR_RENTAL:
      urlRedirect = haveData
        ? `${process.env.REACT_APP_BOOKING_CAR_BETA_DOMAIN_URL}/booking/car/car-ota`
        : `${process.env.REACT_APP_BOOKING_CAR_BETA_DOMAIN_URL}/booking/car/become-agency`
      break
    case MODULE_TYPE.CAR:
      urlRedirect = haveData
        ? `${process.env.REACT_APP_BOOKING_CAR_BETA_DOMAIN_URL}/booking/car/car-ota`
        : `${process.env.REACT_APP_BOOKING_CAR_BETA_DOMAIN_URL}/booking/car/become-agency`
      break
    case MODULE_TYPE.TRAVEL:
      urlRedirect = `${process.env.REACT_APP_TRAVEL_DOMAIN_URL}/request-become-agency`
      break
    case MODULE_TYPE.SUPPLIER:
      if(myShopId) urlRedirect = `${process.env.REACT_APP_SUPPLIER_URL}/shops/${myShopId}?permission=user`
      break
    case MODULE_TYPE.SHOP_ADMIN:
      setIsOpensShopsAdmin(true)
      break
    default:
      break
    }
    if (urlRedirect) window.open(urlRedirect, "_self")
  }
  const getStyleHaveData = (type: string, value: boolean) => {
    if (type === MODULE_TYPE.SHOP || type === MODULE_TYPE.RESTAURANT) {
      return !value ? "opacity-50" : ""
    }
    return ""
  }

  const getShops = () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    new ShopControllerApi(configHeader(SERVICE.SHOP)).shopsShopUserGet(0,100).then((res: any) => {
      const isSpec = res?.data?.data?.is_special_seller || false
      setIsSpecShop(isSpec)
      if(res?.data?.data) {
        if(res.data.data?.userInfoDTOS?.length) {
          const result: ShopAdminType[] = []
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          res.data.data.userInfoDTOS.forEach((i: any) => {
            result.push(i)
          })
          setShops(result)
        }
        setIsHaveShop(!!res.data.data?.is_shop)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  if(isNotHaveData && errorMessage && !loading) return (
    <div className="flex h-screen px-3 text-center items-center justify-center">
      <EmptyPage message={errorMessage}/>
    </div>
  )
  return loading ? (
    <div className="flex h-[540px] items-center justify-center">
      <ProgressBar
        mode="indeterminate"
        style={{ height: "5px", width: "50%", maxWidth: "300px" }}
      ></ProgressBar>
    </div>
  ) : (
    <div className="mt-[24px] md:mt-[96px] flex h-full w-full flex-col items-center justify-center rounded-3 bg-white py-2">
      <div className="flex w-full max-w-[1200px] flex-col">
        <span className="text-center text-[20px] font-bold capitalize leading-[30px] text-gray-900">
          {capitalizedFirst(t("home-page.select-module"))}
        </span>
        <div className="mt-[24px] md:mt-[48px] grid md:grid-cols-6 grid-cols-2 gap-4 px-3 md:px-[0px]">
          {data.map((i) => (
            <div
              onClick={() => handleClickModule(i.type, !!i?.active)}
              key={i.id}
              className={`flex cursor-pointer flex-col items-center justify-center rounded-3 py-[40px] text-gray-700 hover:bg-blue-600 hover:text-white ${getStyleHaveData(
                i.type,
                !!i?.active
              )} ${i.type === MODULE_TYPE.CAR || (i.type === MODULE_TYPE.SUPPLIER && !myShopId) && "hidden"}
              ${(i.type === MODULE_TYPE.SHOP && isSupplier && shops.length < 2) ? "opacity-50" : ""}`}
            >
              <div className="flex items-center justify-center rounded-full bg-white">
                <ImgCustom
                  url={i.icon}
                  alt={i.label}
                  className="!h-[56px] w-[56px] rounded-full"
                />
              </div>       
              <span className="mt-[20px] text-[16px] font-medium leading-[24px]">
                {capitalizedFirst(t(`home-page.module.${i.type.toLowerCase()}`))}
              </span>
            </div>
          ))}
        </div>

      </div>
      {isOpenShopsAdmin ? (
        <ShopAdminModal
          myShopId={myShopId}
          isShow={isOpenShopsAdmin}
          handleClose={() => setIsOpensShopsAdmin(false)}
          shops ={shops}
          isSpecShop={isSpecShop}
          isHaveShop={isHaveShop}
          isSupplier={isSupplier}
        />
      ) : null}
    </div>
  )
}
